import React from "react";
import { graphql } from "gatsby";
import Highlight, { defaultProps } from "prism-react-renderer";
import Prism from "prism-react-renderer/prism";
import vsDark from "prism-react-renderer/themes/vsDark";

(typeof global !== "undefined" ? global : window).Prism = Prism;

require("prismjs/components/prism-java");
require("prismjs/components/prism-r");

export const fragment = graphql`
    fragment microFormattedCode on ContentfulMicroFormattedCode {
        id
        name
        programmingLanguage
        code {
            code
        }
    }
`;

const MicroFormattedCode = ({ item: { programmingLanguage, code } }) => {
    return (
        <div className={"clear-both"}>
            <Highlight
                {...defaultProps}
                theme={vsDark}
                code={code.code}
                language={programmingLanguage?.toLowerCase()}
                className={"clear-both"}
            >
                {({ className, style, tokens, getLineProps, getTokenProps }) => (
                    <pre className={`${className} p-2 rounded overflow-auto`} style={style}>
                        {tokens.map((line, i) => (
                            <div {...getLineProps({ line, key: i })} className={"flex"}>
                                <div className={"w-14 pr-4 text-c_text_grey"}>{i + 1}</div>
                                {line.map((token, key) => (
                                    <span {...getTokenProps({ token, key })} />
                                ))}
                            </div>
                        ))}
                    </pre>
                )}
            </Highlight>
        </div>
    );
};

export default MicroFormattedCode;
